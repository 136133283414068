/* Signup body */
.signup-body {
    background-color: #fff;
    color: #000;
    font-family: Arial, sans-serif;
    margin: 20px auto; /* Center the signup form horizontally */
    max-width: 90%;
  }
  
  /* Signup header */
  .signup-header {
    background-color: #fff;
    color: #000;
    padding: 0;
    text-align: center;
  }
  
  /* Signup banner */
  .signup-banner {
    align-items: center;
    display: flex;
  }
  
  /* Signup navigation */
  .signup-nav {
    margin-left: 80%;
    position: flex;
    right: 0;
    top: 0;
  }
  
  .signup-nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .signup-nav ul li {
    margin-right: 10px;
  }
  
  .signup-nav a {
    border: none;
    border-radius: 5px;
    color: #000;
    font-weight: 700;
    margin-right: 20px;
    padding: 10px;
    text-decoration: none;
  }
  
  /* Signup form input */
  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 4px; /* Add this line for slightly rounded corners */
  }
  
  /* Signup button */
  .signup-button {
    background-color: #4caf50;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    padding: 10px 15px;
  }
  
  .signup-button:hover {
    background-color: #45a049;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .signup-body {
      margin: 0 auto; /* Center on smaller screens as well */
      max-width: 90%;
    }
  
    .signup-header {
      padding: 10px;
    }
  
    .signup-nav {
      margin-top: 10px;
    }
  
    .signup-nav ul {
      flex-direction: column;
    }
  
    .signup-nav ul li {
      margin: 0;
    }
  
    #demoNavItem {
      left: 10px;
    }
  }