.floating-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.widget-button {
  background-color: #00a2ff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.widget-button:hover {
  background-color: #007bbc;
}
