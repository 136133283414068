.chat-widget {
    position: fixed;
    left: -300px; /* Adjust this to hide the widget off-screen */
    top: 50%;
    transform: translateY(-50%);
    transition: left 0.3s ease;
    z-index: 9999; /* Ensure it's above other content */
  }
  
  .chat-widget.open {
    left: 0;
  }
  
  .toggle-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
    border: none;
    background-color: transparent;
  }
  
  .button-container {
    background-color: green;
    color: white;
    padding: 8px 12px;
    border-radius: 20px;
    font-size: 14px;
  }
  
  .chat-content {
    border-radius: 10px; /* Add rounded corners to the chat interface */
    overflow: hidden; /* Ensure content does not overflow beyond rounded corners */
  }
  
  .chat-content iframe {
    border: none; /* Remove default iframe border */
  }
  
  